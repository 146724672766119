<template>
  <v-row class="d-flex flex-row justify-end align-center pr-6">
    <v-col cols="4">
      <v-row class="pr-12">
        <!-- <v-select
            v-model="type"
            :items="typeItems"
            item-text="name"
            item-value="value"
            :label="$t('Activity Type')"
            append-icon="mdi-chevron-down"
            class="mr-3"
            style="max-width: 45%;"
          /> -->
        <v-combobox
          v-on-clickaway="closeMenu"
          :value="selectedTeams"
          :items="teams"
          item-text="fullTeamName"
          outlined
          dense
          hide-details
          multiple
          :label="$t('common.team')"
          append-icon="mdi-chevron-down"
          class="pt-6 pr-6"
          color="primary"
          :menu-props="{ closeOnContentClick: true }"
          @input="teams => $store.commit('academy/updateSelectedTeams', teams)"
          @change="isMenuOpen = true"
          @mousedown="isMenuOpen = true"
        >
          <template #selection="{ item, index }">
            <!-- open dropdown -->
            <v-chip
              v-if="selectedTeams.some(team => team.id === item.id) && isMenuOpen"
              close
              color="primary"
              outlined
              @click:close="$store.commit('academy/updateSelectedTeams', selectedTeams.filter(team => team.id !== item.id))"
            >
              <span>
                {{ item.fullTeamName }}
              </span>
            </v-chip>
            <!-- closed dropdown -->
            <span
              v-else-if="index === 0 && !isMenuOpen"
              class="text-truncate"
              style="max-width: 15rem;"
            >
              {{ item.fullTeamName }}
            </span>
            <span
              v-else-if="index === 1 && !isMenuOpen"
              class="grey--text text-caption ml-1"
            >
              (+{{ selectedTeams.length - 1 }})
            </span>
          </template>
        </v-combobox>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'CalendarFilter',
  mixins: [clickaway],
  data() {
    return {
      isMenuOpen: false,
    }
  },
  computed: {
    ...mapGetters({ teams: 'academy/teams' }),
    ...mapGetters({ selectedTeams: 'academy/selectedTeams' }),
  },
  methods: {
    closeMenu() {
      this.isMenuOpen = false
    },
  },
}
</script>
